<template>
  <div class="card-agent">
    <!-- data card -->
    <div class="card-agent__data-agent">
      <!-- name -->
      <div class="card-agent__data-agent__name">
        <h1>Nama Agen</h1>
        <p>{{ name }}</p>
      </div>
      <!-- address -->
      <div class="card-agent__data-agent__address">
        <h1>Alamat</h1>
        <p>{{ address }}</p>
      </div>
      <!-- telephone -->
      <div class="card-agent__data-agent__telephone">
        <h1>Telepon</h1>
        <p>{{ telephone }}</p>
      </div>
      <!-- button to location -->
      <div class="card-agent__data-agent__button">
        <Button
          text="Lihat Lokasi"
          class="custom-padding"
          :custom-padding="true"
          @click="goToMaps"
        />
      </div>
    </div>
    <!-- end data card -->
  </div>
</template>
  
  <script>
import Button from "@/components/new-button/Button.vue";

export default {
  components: {
    Button,
  },
  props: {
    name: {
      type: String,
      default: ''
    },
    address: {
      type: String,
      default: ''
    },
    telephone: {
      type: String,
      default: ''
    },
    href: {
      type: String,
      default: ''
    },
  },
  methods: {
    goToMaps() {
      window.open("https://maps.google.com/?q=" + this.href, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/css/style.scss";

@mixin font-table() {
  > h1 {
    padding-bottom: 4px;
    color: $color-gray-shade;
    font-weight: 600;
    font-size: 12px;
  }
}

.card-agent {
  margin: auto;
  color: $color-gray-shades-6;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  &__data-agent {
    display: flex;
    justify-content: space-between;
    padding: 20px 24px;
    border-radius: 12px;
    border: 1px solid $color-gray-pensive-5;

    @include for-size(big-desktop) {
      flex-direction: row;
    }

    @include for-size(desktop) {
      flex-direction: row;
    }

    @include for-size(xmobile) {
      flex-direction: column;
      gap: 20px;
      padding: 20px;
    }

    @media (max-width: 375px) {
      flex-direction: column;
      gap: 20px;
    }

    &__name {
      @include for-size(big-desktop) {
        width: 220px;
      }

      @include for-size(desktop) {
        width: 220px;
      }

      @include for-size(tablet-portrait) {
        width: 120px;
      }

      @include for-size(xmobile) {
        width: 100%;
      }

      @include font-table();
    }

    &__address {
      @include for-size(big-desktop) {
        width: 405px;
      }

      @include for-size(desktop) {
        width: 405px;
      }

      @include for-size(tablet-portrait) {
        width: 320px;
      }

      @include for-size(xmobile) {
        width: 100%;
      }

      @media (max-width: 375px) {
        width: 100%;
      }

      @include font-table();
    }

    &__telephone {
      @include for-size(big-desktop) {
        width: 120px;
      }

      @include for-size(desktop) {
        width: 120px;
      }

      @include for-size(tablet-portrait) {
        width: 120px;
      }

      @include for-size(xmobile) {
        width: 100%;
      }

      @include font-table();
    }

    &__button {
      align-items: center;
      display: flex;

      @include for-size(xmobile) {
        display: block;
      }

      @include for-size(mobile) {
        display: block;
      }

    }
  }

  .custom-padding {
    padding: 9px 16px;
  }
}
</style>
