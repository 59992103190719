import AgentDistrictRepository from "../api/agentDistrict";

class AgentDistrictUseCase {
    constructor() {
        /** @type {AgentDistrictRepository} */
        this.api = new AgentDistrictRepository();
    }

    /**
     *
     * @param input {string}
     * @return {Promise<{data: ?District[], err: Error}>}
     */
    async getAllDistrictBySearch(input) {
        const {data, err} = await this.api.findDistrict(input)
        if (data.data) {
            return {data: data.data, err: null}
        }
        return {data: null, err: err}
    }

    /**
     *
     * @param subID {number}
     * @return {Promise<{data: ?POSLocation, err: Error}>}
     */
    async getDataPOSLocation(subID, page, perPage) {
        try {
            const result = await this.api.findAgentInDistrict(subID, page, perPage)
            return {data: result.data, err: result.err}
        } catch (err) {
            return {data: null, err: err}
        }
    }
}

export default new AgentDistrictUseCase()
