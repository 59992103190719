<template>
  <div
    id="agent-location"
    class="agent-location"
  >
    <!-- Banner -->
    <ImageLazy
      :src="imgSrc()"
      alt="cari lokasi agent terdekat"
    />

    <!-- HeadLines -->
    <div
      class="agent-location__headlines"
    >
      <h1>Kirim Paket Makin Dekat</h1>
      <h2>Langsung ke <span>Agen Terdekat</span></h2>
    </div>

    <!-- breadcrumb -->
    <div class="agent-location__breadcrumb">
      <BreadCrumb />
    </div>

    <!-- form -->
    <div class="agent-location__input">
      <!-- title -->
      <div class="agent-location__input__title">
        <h1>Lokasi Agen Lion Parcel</h1>
      </div>

      <!-- label -->
      <div class="agent-location__input__label">
        <p>Cari agen terdekat</p>
      </div>

      <!-- input -->
      <div class="agent-location__input__input">
        <div>
          <form autocomplete="off">
            <Input
              v-model="searchAgentDistrict"
              name="find-location-agent"
              class="agent-input"
              placeholder="Kecamatan/Kota"
              @input="onChange"
            />
          </form>
        </div>
        <div>
          <Button
            class="custom-padding"
            text="Cari"
            @click="eventFindLocation"
          />
        </div>
      </div>
      <OptionSelect
        v-if="showOption"
        decide-class-hover="hover-option-from"
        trigger-class-listener-on-scroll="option-select__content-from"
        :show-loading-search="typing"
        style-display="z-index: 20;width: 100%"
        key-value="sub_district_name,district_name"
        :data-list="listData"
        :capitalize="true"
        @click="chooseMe"
      />
    </div>

    <!-- Content -->
    <div class="agent-location__content">
      <!-- Head Content -->

      <!-- preload -->
      <div
        v-if="isLoading"
        id="loader-agent-location"
      >
        <div id="loader-agent-location__wrapper">
          <div
            class="skeleton-box"
            style="width: 20vw; height: 1em; border-radius: 10px"
          />
          <div
            class="skeleton-box"
            style="width: 20vw; height: 1em; border-radius: 10px"
          />
          <div
            class="skeleton-box"
            style="width: 20vw; height: 1em; border-radius: 10px"
          />
          <div
            class="skeleton-box"
            style="
              width: 100%;
              height: 10em;
              border-radius: 10px;
              margin-top: 4em;
            "
          />
        </div>
      </div>

      <div
        v-if="resultPOSLocation && resultPOSLocation.err"
        class="not-found"
      >
        <h1>Data tidak ditemukan</h1>
      </div>

      <div
        v-if="isresultPOSLocation"
        class="agent-location__content__head-content"
      >
        <h1>Agen Lion Parcel</h1>
        <p>{{ resultPOSLocation.data.consolidator.consolidator_name }}</p>
        <p>{{ resultPOSLocation.data.consolidator.consolidator_address }}</p>
        <p>{{ resultPOSLocation.data.consolidator.contact_number }}</p>
      </div>

      <!-- card -->
      <div
        v-if="isresultPOSLocation"
        class="agent-location__content__card"
      >
        <div
          v-for="(agent, i) in resultPOSLocation.data.data"
          :key="agent + '-' + i"
        >
          <CardAgent
            :name="agent.npos_name"
            :address="agent.address"
            :telephone="agent.contact_number"
            :href="agent.latlong"
          />
        </div>
      </div>

      <!-- pagination -->
      <div
        v-if="isresultPOSLocation"
        class="agent-location__content__pagination"
      >
        <pagination
          v-model="pagination.page"
          :total-all-item="pagination.totalAllItem"
          :per-page="pagination.perPage"
          :disable="true"
          @prev="counter('decrement')"
          @next="counter('increment')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from '@/components/new-breadcrumb/BreadCrumb.vue';
import Input from '@/components/new-input/Input.vue';
import Button from '@/components/new-button/Button.vue';
import CardAgent from './components/CardAgent.vue';
import Pagination from '../../components/new-pagination/Pagination.vue';
import OptionSelect from '../../components/new-option-select/OptionSelect.vue';
import AgentDistrictUseCase from './app/usecase/agentDistrict';
import mixinMobile from '../../misc/mixinMobile';
import mixinMetaInfo from '../../misc/mixinMetaInfo';
import ImageLazy from '@/components/ImageLazy/index.vue';

export default {
  name: 'AgentLocation',
  components: {
    BreadCrumb,
    Input,
    Button,
    CardAgent,
    Pagination,
    OptionSelect,
    ImageLazy,
  },
  mixins: [mixinMobile, mixinMetaInfo],
  metaInfo() {
    return this.SEO({
      channel: 'channel',
      title: this.metaInfo.meta_info_title,
      description: this.metaInfo.meta_info_description,
      keywords: this.metaInfo.meta_info_keyword,
      publishedTime: this.metaInfo.meta_info_publish_time,
      image: this.metaInfo.attachment_meta_info_photo_url,
    });
  },
  data() {
    return {
      searchAgentDistrict: '',
      showOption: false,
      typing: false,
      resultPOSLocation: null,
      listData: {},
      isLoading: false,
      isresultPOSLocation: false,
      /** @type {District} */
      searchDistrict: {
        district_name: '',
        sub_district_id: 0,
        sub_district_name: '',
      },
      debounce: {
        timeout: 0,
        duration: 1500,
      },
      /** @type {Pagination} */
      pagination: {
        page: 0,
        totalAllItem: 0,
        perPage: 0,
      },
    };
  },
  mounted() {
    window.addEventListener('resize', this.removePaginationDesc);
    const local = JSON.parse(localStorage.getItem('location-agent'));
    if (local && local.district_name) {
      this.searchAgentDistrict = `${local.sub_district_name}, ${local.district_name}`;
      this.findLocation(local.sub_district_id);
      this.chooseMe(local);
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.removePaginationDesc);
  },
  destroyed() {
    localStorage.removeItem('location-agent');
  },
  methods: {
    onChange(val) {
      this.showOption = true;
      this.typing = true;
      if (val.length > 0) {
        try {
          if (this.debounce.timeout) {
            clearTimeout(this.debounce.timeout);
          }

          this.debounce.timeout = setTimeout(async () => {
            this.listData = await AgentDistrictUseCase.getAllDistrictBySearch(val);
            this.typing = false;
          }, this.debounce.duration);
        } catch (error) {
          this.typing = false;
        }
      } else {
        this.showOption = false;
        this.typing = false;
      }
    },
    counter(type) {
      window.scrollTo(0, 200);
      const totalPage = Math.ceil(
        this.pagination.totalAllItem / this.pagination.perPage,
      );
      if (type === 'increment') {
        if (this.pagination.page < totalPage) {
          this.pagination.page += 1;
        }
        this.findLocation(
          this.searchDistrict.sub_district_id,
          this.pagination.page,
          this.pagination.perPage,
        );
      } else if (this.pagination.page > 1) {
        this.pagination.page -= 1;
        this.findLocation(
          this.searchDistrict.sub_district_id,
          this.pagination.page,
          this.pagination.perPage,
        );
      }
    },
    chooseMe(val) {
      /** @param val {District} */
      this.searchDistrict = val;
      this.showOption = false;
      const picked = `${this.searchDistrict.sub_district_name
      }, ${
        this.searchDistrict.district_name}`;
      this.searchAgentDistrict = picked.toLowerCase();
    },
    eventFindLocation() {
      if (
        this.searchAgentDistrict.length !== 0
        && this.searchDistrict.district_name !== ''
        && this.searchDistrict.sub_district_id > 0
      ) {
        this.findLocation(this.searchDistrict.sub_district_id);
      } else {
        const el = this.$el.querySelector("input[name='find-location-agent']");
        if (el) {
          el.focus();
        }
      }
    },
    async findLocation(id, page, perPage) {
      this.isLoading = true;
      this.isresultPOSLocation = false;
      this.resultPOSLocation = undefined;
      await AgentDistrictUseCase.getDataPOSLocation(id, page, perPage)
        .then((res) => {
          this.resultPOSLocation = res;
          if (res.data) {
            this.isresultPOSLocation = true;
            this.pagination = {
              page: Number(res.data.pagination.page),
              perPage: Number(res.data.pagination.per_page),
              totalAllItem: res.data.pagination.total_all_data,
            };
          }
          this.removePaginationDesc();
        })
        .catch((err) => {
          this.isresultPOSLocation = false;
          this.resultPOSLocation.err = err.err;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    imgSrc() {
      return this.isMobileSize
        ? '/images/agent-location/Lokasi_POS_mobile.webp'
        : '/images/agent-location/Lokasi_POS.webp';
    },
    removePaginationDesc() {
      if (this.isresultPOSLocation) {
        this.removeText = setTimeout(() => {
          const el = document.querySelector('.pagination-desc');
          if (window.innerWidth < 500) {
            el.style.cssText = 'display: none;';
          } else {
            el.style.cssText = 'display: inline;';
          }
        }, 300);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
